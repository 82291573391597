import UserReferral, { SerializedUserReferral } from "@/models/user-referral";
import {
    SerializedCamwaterSettings,
    SerializedCanalplusSettings,
    SerializedUserAirtimeSettings,
    SerializedUserDstvSettings,
    SerializedUserEneoSettings,
    SerializedUserExchangeSettings,
    SerializedUserNotificationSettings,
    SerializedUserProfileSettings,
    UserAirtimeSettings,
    UserCamwaterSettings,
    UserCanalplusSettings,
    UserDStvSettings,
    UserEneoSettings,
    UserExchangeSettings,
    UserNotificationSettings,
    UserProfileSettings,
} from "@/models/user-settings";

export interface SerializedUserProfile {
    user_id: string | null;
    updated_at: string;
    dstv: SerializedUserDstvSettings | null;
    profile: SerializedUserProfileSettings | null;
    airtime: SerializedUserAirtimeSettings | null;
    notification: SerializedUserNotificationSettings | null;
    referral: SerializedUserReferral | null;
    eneo: SerializedUserEneoSettings | null;
    camwater: SerializedCamwaterSettings | null;
    exchange: SerializedUserExchangeSettings | null;
    canalplus: SerializedCanalplusSettings | null;
}

export default class UserProfile {
    private readonly _userId: string | null;
    private readonly _updatedAt: Date;
    private readonly _dstv: UserDStvSettings | null;
    private readonly _profile: UserProfileSettings | null;
    private readonly _airtime: UserAirtimeSettings | null;
    private readonly _notification: UserNotificationSettings | null;
    private readonly _referral: UserReferral | null;
    private readonly _eneo: UserEneoSettings | null;
    private readonly _camwater: UserCamwaterSettings | null;
    private readonly _exchange: UserExchangeSettings | null;
    private readonly _canalplus: UserCanalplusSettings | null;

    constructor(profile: SerializedUserProfile) {
        this._userId = profile.user_id;
        this._updatedAt = new Date(profile.updated_at);
        this._referral = profile.referral
            ? new UserReferral(profile.referral)
            : null;
        this._dstv = profile.dstv ? new UserDStvSettings(profile.dstv) : null;
        this._profile = profile.profile
            ? new UserProfileSettings(profile.profile)
            : null;
        this._airtime = profile.airtime
            ? new UserAirtimeSettings(profile.airtime)
            : null;
        this._notification = profile.notification
            ? new UserNotificationSettings(profile.notification)
            : null;
        this._eneo = profile.eneo ? new UserEneoSettings(profile.eneo) : null;
        this._camwater = profile.camwater
            ? new UserCamwaterSettings(profile.camwater)
            : null;
        this._exchange = profile.exchange
            ? new UserExchangeSettings(profile.exchange)
            : null;
        this._canalplus = profile.canalplus
            ? new UserCanalplusSettings(profile.canalplus)
            : null;
    }

    get canalplus(): UserCanalplusSettings | null {
        return this._canalplus;
    }

    get referral(): UserReferral | null {
        return this._referral;
    }

    get name(): string | null {
        return this._profile?.name ?? null;
    }

    get email(): string | null {
        return this._profile?.email ?? null;
    }

    get userId(): string | null {
        return this._userId;
    }

    get updatedAt(): Date {
        return this._updatedAt;
    }

    get camwater(): UserCamwaterSettings | null {
        return this._camwater;
    }

    get dstvPackageID(): string | null {
        return this._dstv?.dstvPackageId ?? null;
    }

    get smartcardNumber(): string | null {
        return this._dstv?.smartcardNumber ?? null;
    }

    get paymentMethod(): string | null {
        return this._profile?.paymentMethod ?? null;
    }

    get paymentPhoneNumber(): string | null {
        return this._profile?.paymentPhoneNumber ?? null;
    }

    get dstv(): UserDStvSettings | null {
        return this._dstv;
    }

    get profile(): UserProfileSettings | null {
        return this._profile;
    }

    get airtime(): UserAirtimeSettings | null {
        return this._airtime;
    }

    get eneo(): UserEneoSettings | null {
        return this._eneo;
    }

    get exchange(): UserExchangeSettings | null {
        return this._exchange;
    }

    get notification(): UserNotificationSettings | null {
        return this._notification;
    }
}
